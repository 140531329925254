import type { App, InjectionKey } from 'vue';

export const chatWidgetInjectionKey: InjectionKey<ChatWidget> = Symbol('chat-widget');

declare global {
  interface Window {
    zESettings?: typeof zEDefaultSettings;
    zE: {
      (target: 'webWidget', command: 'setLocale', locale: string): void;
      (
        target: 'webWidget',
        command: 'updateSettings',
        settings: {
          webWidget: {
            color: {
              theme: string;
              launcher: string;
              launcherText: string;
            };
          };
        },
      ): void;
    };
  }
}

type SetLocale = (locale: string) => void;
type UpdateTheme = (primaryColor: string, textColor: string) => void;

export type ChatWidget = {
  setLocale: SetLocale;
  updateTheme: UpdateTheme;
};

export type ZendeskOptions = {
  key: string;
  initialQueue: Commands;
};

const zEDefaultSettings = {
  webWidget: {
    chat: {
      departments: {
        enabled: [],
      },
    },
    offset: {
      mobile: {
        horizontal: '0px',
        vertical: '41px',
      },
    },
  },
};

type Commands = (
  | { command: 'setLocale'; locale: string }
  | {
      command: 'updateTheme';
      primaryColor: string;
      textColor: string;
    }
)[];

export const createChatWidget = (options: ZendeskOptions) => ({
  install: (app: App): void => {
    let queue: Commands = options.initialQueue;

    const doc = window.document;
    const element = doc.createElement('script');
    element.id = 'ze-snippet';
    element.src = `https://static.zdassets.com/ekr/snippet.js?key=${options.key}`;
    element.onload = () => {
      queue.forEach((queueItem) => {
        if (queueItem.command === 'setLocale') {
          setLocale(queueItem.locale);
        }
        if (queueItem.command === 'updateTheme') {
          updateTheme(queueItem.primaryColor, queueItem.textColor);
        }
      });
      queue = [];
    };
    doc.body.appendChild(element);

    window.zESettings = zEDefaultSettings;

    const loaded = (): boolean => !!window.zE;

    const setLocale: SetLocale = (locale: string) => {
      if (loaded()) {
        window.zE('webWidget', 'setLocale', locale);
      } else {
        queue.push({ command: 'setLocale', locale });
      }
    };

    const updateTheme: UpdateTheme = (primaryColor: string, textColor: string) => {
      if (loaded()) {
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            color: {
              theme: primaryColor,
              launcher: primaryColor,
              launcherText: textColor,
            },
          },
        });
      } else {
        queue.push({
          command: 'updateTheme',
          primaryColor,
          textColor,
        });
      }
    };

    const chatWidget: ChatWidget = {
      setLocale,
      updateTheme,
    };

    app.provide(chatWidgetInjectionKey, chatWidget);
  },
});
