import { inject, InjectionKey } from 'vue';
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordRaw,
} from 'vue-router';
import { RouteName } from './routes';

export type AppRouter = ReturnType<typeof createAppRouter>;

export type RouteRecord = RouteRecordRaw & {
  name: RouteName;
  children?: RouteRecord[];
};

export const routerKey: InjectionKey<AppRouter> = Symbol('app-router');

export function useAppRouter() {
  const router = inject<AppRouter>(routerKey);
  if (!router) {
    throw new Error('router key not provided');
  }

  return router;
}

// if vue-router version is above 4.3.2 try to remove ErrorListener
type ErrorListener = (error: any, to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => any;

export function createAppRouter(config: Array<RouteRecord>) {
  const router = createRouter({
    routes: config,
    history: createHistory(),
  });

  return {
    ...router,
    onError: router.onError as (handler: ErrorListener) => () => void, // if vue-router version is above 4.3.2 try to remove onError, since it should be applied on spread above
    router,
    push: (name: RouteName, params?: Record<string, string | number | undefined>) => router.push({ name, params }),
    replace: (name: RouteName, params?: Record<string, string | number | undefined>) =>
      router.replace({ name, params }),
  };

  function createHistory() {
    if (!import.meta.env.DEV) {
      const history = createMemoryHistory();
      history.replace(window.location.pathname);
      return history;
    }

    return createWebHistory(import.meta.env.BASE_URL);
  }
}
