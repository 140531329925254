import { RouteRecord } from './router';

export type RouteName =
  | 'SelectPayout'
  | 'EmailVerification'
  | 'CodeVerification'
  | 'Kyc'
  | 'PaymentInstrumentCard'
  | 'PaymentInstrumentSepa'
  | 'SendCryptoConsent'
  | 'SendCrypto'
  | 'WalletSendCrypto'
  | 'TransactionRateChanged'
  | 'TransactionConfirmation'
  | 'TransactionCancelation'
  | 'TransactionRejection'
  | 'TransactionApproval'
  | 'TransactionProcessing'
  | 'TransactionRateChangedConcludedProcessing'
  | 'TransactionSuccess'
  | 'TransactionFailed'
  | 'Home'
  | 'NotFound'
  | 'CardVerification'
  | 'SepaVerification'
  | 'UserVerification'
  | 'PhoneVerification'
  | 'PhoneVerificationStart'
  | 'PhoneVerificationVerify'
  | 'CardRecollectionSubmit';

export const routes: Array<RouteRecord> = [
  {
    path: '/:tid([\\da-f]{8}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{12})',
    name: 'Home',
    component: () => import('../../home/PageHome.vue'),
    props: true,
    children: [
      {
        path: 'select-payout',
        name: 'SelectPayout',
        component: () => import('../../payout-option/PageSelectPayout.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'verify',
        name: 'UserVerification',
        component: () => import('../../user-verification/PageUserVerification.vue'),
        redirect: {
          name: 'EmailVerification',
        },
        children: [
          {
            path: 'email',
            name: 'EmailVerification',
            component: () => import('../../user-verification/email/PageEmailVerification.vue'),
          },
          {
            path: 'code',
            name: 'CodeVerification',
            component: () => import('../../user-verification/code/PageCodeVerification.vue'),
          },
        ],
      },
      {
        path: 'kyc',
        name: 'Kyc',
        component: () => import('../../kyc/PageKyc.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-instrument-card',
        name: 'PaymentInstrumentCard',
        component: () => import('../../payment-instrument/card/PagePaymentInstrumentCard.vue'),
        meta: {
          disabledBackButton: true,
        },
        children: [
          {
            path: 'verification',
            name: 'CardVerification',
            component: () => import('../../payment-instrument/PageVerification.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
        ],
      },
      {
        path: 'payment-instrument-sepa',
        name: 'PaymentInstrumentSepa',
        component: () => import('../../payment-instrument/sepa/PagePaymentInstrumentSepa.vue'),
        meta: {
          disabledBackButton: true,
        },
        children: [
          {
            path: 'verification',
            name: 'SepaVerification',
            component: () => import('../../payment-instrument/PageVerification.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
        ],
      },
      {
        path: 'phone-verification',
        name: 'PhoneVerification',
        component: () => import('../../phone-verification/PagePhoneVerification.vue'),
        meta: {
          disabledBackButton: true,
        },
        redirect: {
          name: 'PhoneVerificationStart',
        },
        children: [
          {
            path: 'start',
            name: 'PhoneVerificationStart',
            component: () => import('../../phone-verification/start/PagePhoneVerificationStart.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
          {
            path: 'verify/:durationSeconds/:processId',
            name: 'PhoneVerificationVerify',
            component: () => import('../../phone-verification/verify/PagePhoneVerificationVerify.vue'),
            meta: {
              disabledBackButton: true,
            },
            props: (p) => ({
              durationSeconds: Number(p.params.durationSeconds),
              processId: p.params.processId,
            }),
          },
        ],
      },
      {
        path: 'changed-rate',
        name: 'TransactionRateChanged',
        component: () => import('../../changed-rate/PageTransactionRateChanged.vue'),
        meta: {
          disabledBackButton: true,
        },
        redirect: {
          name: 'TransactionConfirmation',
        },
        children: [
          {
            path: 'confirmation',
            name: 'TransactionConfirmation',
            component: () => import('../../changed-rate/approval/PageTransactionConfirmation.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
          {
            path: 'approval',
            name: 'TransactionApproval',
            component: () => import('../../changed-rate/approval/PageTransactionApproval.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
          {
            path: 'cancelation',
            name: 'TransactionCancelation',
            component: () => import('../../changed-rate/cancelation/PageTransactionCancelation.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
          {
            path: 'rejection',
            name: 'TransactionRejection',
            component: () => import('../../changed-rate/rejection/PageTransactionRejection.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
        ],
      },
      {
        path: 'send-crypto-consent',
        name: 'SendCryptoConsent',
        component: () => import('../../send-crypto-consent/PageSendCryptoConsent.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'send-crypto',
        name: 'SendCrypto',
        component: () => import('../../send-crypto/PageSendCrypto.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'wallet-send-crypto',
        name: 'WalletSendCrypto',
        component: () => import('../../wallet-send-crypto/PageWalletSendCrypto.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'transaction-success',
        name: 'TransactionSuccess',
        component: () => import('../../transaction/success/PageTransactionSuccess.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'transaction-processing',
        name: 'TransactionProcessing',
        component: () => import('../../transaction/processing/PageTransactionProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'transaction-rate-change-concluded-processing',
        name: 'TransactionRateChangedConcludedProcessing',
        component: () => import('../../transaction/processing/PageTransactionRateChangedConcludedProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'transaction-failed',
        name: 'TransactionFailed',
        component: () => import('../../transaction/failed/PageTransactionFailed.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'card-recollection-submit',
        name: 'CardRecollectionSubmit',
        component: () => import('../../payment-instrument/card/recollection/PageCardRecollectionSubmit.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../../not-found/PageNotFound.vue'),
  },
];
